import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Rohan Peri | Developer', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: 'Welcome to my website!', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hey, my name is',
  name: 'Rohan',
  subtitle: `I'm a Mechatronics Engineer`,
  cta: 'Find out More',
};

// ABOUT DATA
export const aboutData = {
  img: 'NewProfilePic.jpeg',
  paragraphOne: `I'm a third-year Mechatronics Engineering student at the University of Waterloo with a passion for robotics and a knack for software development.`,
  paragraphTwo: `I'm experienced and interested in embedded software engineering and firmware development.`,
  paragraphThree: ` `,
  resume: 'https://drive.google.com/file/d/1DwAToPDzXDsl8d2LDNWE840OdBcdRsmR/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'RealTemp.jpg',
    title: 'RealTemp',
    info: `A real-time embedded systems project to help gain an understanding of RTOS fundamentals such as scheduling, tasks, priorities, and queues. Created a series of LEDs that tell you how warm your room is by creating a task which measures room temperature using a TMP102 sensor and I2C, then putting that value into a queue. The second task receives temperature data from this queue and uses it to update the strip of LEDs. A future improvement would be integrating a DC motor that acts as a fan which can cool the room down.`,
    info2: `Skills & Tools: C, FreeRTOS, I2C, STM32F767 Microcontroller`,
    url: 'https://drive.google.com/file/d/1CxCEilgngU1GPsF856IsTzhS2-I3HScR/view?usp=sharing',
    repo: 'https://github.com/rohanperi/RealTemp',
  },
  {
    id: nanoid(),
    img: 'STM32F767.jpeg',
    title: 'Custom GPIO Driver',
    info: `A driver made from scratch with functions to interact with the input and output pins of a microcontroller. Identified the correct locations for clock and bit initialization by interpreting peripherals and registers on the STM32F767`,
    info2: `Skills & Tools: C, STM32F767 Microcontroller, MCU Peripheral Access Layer`,
    url: 'https://github.com/rohanperi/GPIO-Driver',
  },
  {
    id: nanoid(),
    img: 'robotPictures.jpg',
    title: 'Maze-Solving Robot',
    info:
      'This robot is capable of solving the maze-challenge, which was to read the number of black lines that are on the corner edge of the board (like a bar code) and go to the corresponding final position on the board. For example, if 3 black lines were laid down, the robot should navigate to position 3 on the maze board to successfully pass! This project was created with the help of a PIC Microcontroller and custom-designed PCBs that use light sensors to detect the colour on the board and navigate the robot accordingly with the help of my code!',
    info2: 'Skills & Tools: C++, Soldering, Electrical Design',
    url: 'https://drive.google.com/file/d/1L3HprZbEVakpOWk0GWRy_43bsRTnFNLn/view?usp=sharing',
  },
  {
    id: nanoid(),
    img: 'face-genie-project.png',
    title: 'Face Genie',
    info: `Face genie is a full react application that uses Clarifai's face recognition API to detect the faces in the images that a person uploads! It has a fully integrated registration and sign-in feature which allows people to create accounts where they can track data about their usage of the app!`,
    info2: `Skills & Tools: React.js, HTML, CSS, Clarifai API, REST API, Node.js + Express.js, PostgreSQL`,
    url: 'https://face-genie.herokuapp.com/',
    repo: 'https://github.com/rohanperi/FaceGenie', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'sumtext.png',
    title: 'SumText',
    info:
      'A versatile mobile app that can recognize the text inside of pictures that a user takes using an optical character recognition (OCR) model from Firebase MLKit. Users are then allowed to create, save, and organize notes that utilize the text which they recognized from their documents! ',
    info2: 'Skills & Tools: React Native, Firebase MLKit, JavaScript',
    url: 'https://github.com/Rukshi101/TextRex',
    // repo: 'https://github.com/abhishekmagdum/eduLEAP', if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: '2D-Plotter.jpeg',
    title: '2-Dimensional Plotter',
    info:
      'Custom-built plotting device that came to life with the help of the Lego EV3. A rack and pinion design allows the plotter, which is driven by two lego EV3 motors, to move along an X-Y Plane and plot pictures with ease. The racks were made designed using SolidWorks and printed using a 3D-printer. C++ was the language used to interact with the Lego EV3 so that the motors could be programmed to plot a picture by just using a text file of (X, Y) coordinates.',
    info2: 'Skills & Tools: C++, SolidWorks',
    url: 'https://github.com/rohanperi/2DPlotter',
    repo: 'https://github.com/rohanperi/2DPlotter', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'eduleap.png',
    title: 'EduLeap',
    info:
      'An educational platform for teachers to make classroom teaching exciting through incentivization! This project helped to introduce me to web development and give me a good chance to practice frontend design. An easy-to-use student-login was made so that children can track their progress towards incentives in the classroom! Teachers had a separate login where they can track, add, or edit incentives and incentive progress, along with keep class attendance charts. ',
    info2: 'Skills & Tools: HTML, CSS, JavaScript, Bootstrap',
    url: 'https://devpost.com/software/eduleap',
    repo: 'https://github.com/abhishekmagdum/eduLEAP', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Interested in working with me? Awesome!',
  btn: 'Lets Talk',
  email: 'r2peri@uwaterloo.ca',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/rohan-peri/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/rohanperi',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
